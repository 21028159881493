.table .ant-table {
  direction: rtl !important;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #3b3838;
  color: #fff;
}

.ant-table-thead > tr > th {
  font-size: 10px;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: #fff;
}

tr:nth-child(odd) {
  background: #d4d1dd;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.ant-tooltip-inner {
  direction: rtl;
}
/* .ant-table-row:hover {
  background: #bab8c2;
} */

.ant-table-filter-dropdown-btns > button[type="button"] {
  background-color: #722ed1;
  border-color: #722ed1;
  color: #fff;
}
