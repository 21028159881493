.form {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(10px);
    background: linear-gradient(
        0deg,
        rgb(172, 151, 236),
        rgba(245, 245, 245, 0.3)
      ),
      url("../asset/images/login2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  button {
    background-color: rgb(172, 151, 236);
  }
  .title {
    color: rgb(95, 75, 155);
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
  }
  .card {
    direction: rtl;
    min-height: 400px;
    min-width: 400px;
    img {
      width: 150px;
      padding-bottom: 15px;
    }
  }

  @media (max-width: 568px) {
    .card {
      min-height: 300px;
      min-width: 300px;
    }
  }
}
