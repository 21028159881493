.layout {
  .sider {
    height: 100vh;
    position: fixed;
    min-width: 72px !important; //override antd default
    max-width: 72px !important; //override antd default
    width: 72px;
    right: 0;
    top: 0;
    bottom: 0;
    direction: rtl;
    background: #14014b;
    transition: 0.5s cubic-bezier(0.36, -0.01, 0, 0.77);
    &.active {
      overflow: auto;
      min-width: 250px !important; //override antd default
      max-width: 250px;
      width: 250px !important; //override antd default
      @media (max-width: 992px) {
        transition: all 0.5s cubic-bezier(0.52, 0.35, 0.7, 0.37) ease-in-out;
        position: absolute !important;
        z-index: 100;
        height: 100vh;
        overflow: auto;
      }
      @media (max-width: 576px) {
        &::after {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          width: calc(100vw - 250px);
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          pointer-events: none;
          transition: all 0.5s cubic-bezier(0.52, 0.35, 0.7, 0.37) ease-in-out;
        }
      }
    }
  }

  .sidebarItems {
    li {
      padding-right: 21px !important;
      &:hover {
        color: rgb(125, 99, 181) !important;
      }
    }
    li:global(.ant-menu-item) {
      color: #fff;
    }
    li:global(.ant-menu-item-selected) {
      color: rgb(125, 99, 181);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background: linear-gradient(rgb(125, 99, 181), #4c3977);
    @media (max-width: 576px) {
      justify-content: center;
      padding: 0 10px;
    }
    img {
      width: 120px;
      height: 45px;
      @media (max-width: 768px) {
        width: 100px;
        height: 40px;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    span {
      font-weight: 600;
      color: #fff;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  .icon {
    & > svg {
      width: 22px;
      height: 22px;
    }
  }
  .avatarContainer {
    display: none;
    &.active {
      display: flex;
      width: 100%;
    }
  }
  .siderTop {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    width: calc(100% - 104px);
  }
  .avatarNameSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 23px 5px 0px 15px;
    max-width: 130px;
    color: #fff;
  }
  .avatarName {
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .avatarRegion {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    div:global(.ant-select-selector) {
      color: #fff;
    }
    span:global(.ant-select-arrow) {
      right: 0;
      left: auto;
      color: #fff;
    }
    span:global(.ant-select-selection-item) {
      padding-right: 5px;
      color: unset;
    }
  }
  .avatar {
    margin: 10px 20px 10px 0px;
    background-color: rgb(221, 221, 221);
  }
  .doubleLeft {
    color: #fff;
    padding: 30px 26px 0px;
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .doubleRight {
    color: #fff;
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .hide {
    display: none;
  }
  .secondaryLayout {
    position: relative;
    margin-right: 72px !important; //override antd default
    min-height: 100vh;
    background-color: rgb(242, 242, 242) !important; //override antd default
    transition: 0.5s cubic-bezier(0.36, -0.01, 0, 0.77);
    &.active {
      margin-right: 250px !important; //override antd default
      transition: 0.5s cubic-bezier(0.36, -0.01, 0, 0.77);
      @media (max-width: 992px) {
        margin-right: 0 !important;
      }
    }
  }
  .content {
    margin: 24px 16px 0;
    overflow: initial;
  }

  .footer {
    background-color: rgb(242, 242, 242) !important; //override antd default
    text-align: center;
    direction: rtl;
    @media (max-width: 576px) {
      font-size: 11px;
      padding: 18px 5px;
    }
  }
}
