.header {
  display: flex;
  direction: rtl;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

.dashboardTop {
  direction: rtl;
  margin-bottom: 10px !important;
}
