.table .ant-table {
  direction: rtl !important;
}

.ant-table-wrapper.customTable
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #3b3838;
  color: #fff;
}
.ant-table-wrapper.customTable .ant-table-cell {
  text-align: center;
}
.ant-table-wrapper.customTable .ant-table-tbody > tr > td {
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
}
.ant-table-wrapper.customTable .ant-table-thead > tr > th {
  font-size: 14px;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: #fff;
}

.ant-table-wrapper.customTable tr {
  background: #d4d1dd;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.ant-tooltip-inner {
  direction: rtl;
}
/* .ant-table-row:hover {
  background: #bab8c2;
} */
