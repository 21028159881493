.smallCard {
  display: flex;
  flex-direction: column;
  .value {
    align-self: center;
    justify-self: end;
    font-size: 16px;
    font-weight: 600;
  }
}
